import { CsprClickThemes } from '@make-software/csprclick-ui';
export const AppTheme = {
    dark: {
        ...CsprClickThemes.dark,
        topBarBackground: 'rgb(19, 25, 51)',
        backgroundColor: '#0f1429',
        clickLogo: '#ff0012',
        contentPrimary: '#DADCE5',
        contentSecondary: '#A8ADBF',
        fillSecondary: '#1B254A',
        backgroundPrimary: '#161D3B',
        sectionBackground: '#050B23',
        fillPrimaryBlue: '#8FA6FF',
        linkColor: 'rgb(218, 220, 229)',
        containerBackground: '#2d2d2d',
        codeColor: '#D9D9D9',
    },
    light: {
        ...CsprClickThemes.light,
        topBarBackground: 'rgb(24, 29, 64)',
        backgroundColor: '#f2f3f5',
        clickLogo: '#ff0012',
        contentPrimary: '#1A1919',
        fillSecondary: '#F5F5F7',
        backgroundPrimary: '#FFFFFF',
        contentSecondary: '#84868C',
        sectionBackground: '#EAEBEE',
        fillPrimaryBlue: '#0021A5',
        linkColor: 'rgb(26, 25, 25)',
        containerBackground: '#2d2d2d',
        codeColor: '#737373',
    },
};
